import { Text, HStack, IconButton } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ActionButton } from "components/utils/ActionButton";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "components/utils/Table";
import { ContextualMenuIcon } from "components/Icons";
import {
  DeviceTypeLabel,
  DeviceCompleteNameLabel,
} from "features/devices/components";
import { OrderItemStatusLabel } from "features/order-items/components/OrderItemStatusLabel";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";
import {
  CustomerDeviceDataFragment,
  DeviceDataFragment,
  OrderItemDataFragment,
  OrderItemSubcomponentDataFragment,
} from "data";
import { useOrderItemContextMenu } from "features/order-items/context/OrderItemContextMenu";
import { ReplaceSubcomponentAction } from "features/order-items/components/ReplaceSubcomponentAction";
import { ReceiveOrderItemAction } from "features/order-items/components/ReceiveOrderItemAction";
import { OrderItemReplacementIndicator } from "features/order-items/components/OrderItemReplacementIndicator";
import { MinerIpAddressForm } from "features/order-items/components/MinerIpAddressForm";

interface NonMinerOrderItemSummaryProps {
  orderItem: OrderItemDataFragment;
}

interface MinerOrderItemSummaryProps extends NonMinerOrderItemSummaryProps {
  orderItemSubcomponents: OrderItemSubcomponentDataFragment[];
  customerDeviceForSubcomponent: (
    orderItemSubcomponent: OrderItemSubcomponentDataFragment
  ) => CustomerDeviceDataFragment | null;
  orderItemForSubcomponent: (
    orderItemSubcomponent: OrderItemSubcomponentDataFragment
  ) => OrderItemDataFragment | null;
  onDeleteSubcomponent: (
    orderItemSubcomponent: OrderItemSubcomponentDataFragment
  ) => Promise<void>;
}

export const NonMinerOrderItemSummary = (
  props: NonMinerOrderItemSummaryProps
) => {
  return null;
};

export const MinerOrderItemSummary = (props: MinerOrderItemSummaryProps) => {
  const { openMenu } = useOrderItemContextMenu();
  const {
    orderItemSubcomponents,
    customerDeviceForSubcomponent,
    orderItemForSubcomponent,
    onDeleteSubcomponent,
    orderItem,
  } = props;
  return (
    <>
      <MinerIpAddressForm orderItem={orderItem} />
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>Device</Th>
              <Th>Status</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {orderItemSubcomponents.map((ois) => {
              const orderItem = orderItemForSubcomponent(ois);
              const customerDevice = customerDeviceForSubcomponent(ois);
              const isDeviceCheckedIn = customerDevice !== null;
              return (
                <Tr
                  data-cy="order-item-subcomponent"
                  data-order-item-subcomponent-id={ois.id}
                  data-order-item-subcomponent-device-type={
                    ois.attributes?.device?.data?.attributes?.type
                  }
                  {...(orderItem
                    ? {
                        "data-order-item-id": orderItem.id,
                        "data-order-item-status": orderItem.attributes?.status,
                      }
                    : {})}
                  key={ois.id}
                >
                  <Td>
                    <DeviceTypeLabel
                      showTypeIcon
                      deviceType={
                        ois.attributes?.device?.data?.attributes?.type
                      }
                    />
                  </Td>
                  <Td>
                    {isDeviceCheckedIn ? (
                      <Link
                        to={`/orders/items/${ois.attributes?.order_item?.data?.id}`}
                      >
                        <HStack fontWeight="bold">
                          <OrderItemReplacementIndicator
                            orderItem={orderItem}
                          />
                          <DeviceCompleteNameLabel
                            showMakeIcon
                            device={
                              ois.attributes?.device?.data as DeviceDataFragment
                            }
                          />
                          {customerDevice ? (
                            <CustomerDeviceShortIdLabel
                              customerDevice={customerDevice}
                            />
                          ) : null}
                        </HStack>
                      </Link>
                    ) : (
                      <DeviceCompleteNameLabel
                        showMakeIcon
                        device={
                          ois.attributes?.device?.data as DeviceDataFragment
                        }
                      />
                    )}
                    {customerDevice?.attributes?.serialNumber ? (
                      <Text
                        pl={6}
                        color="gray.500"
                      >{`serial #: ${customerDevice?.attributes?.serialNumber}`}</Text>
                    ) : null}
                  </Td>
                  <Td>
                    {orderItem ? (
                      <OrderItemStatusLabel orderItem={orderItem} />
                    ) : (
                      <Text>N/A</Text>
                    )}
                  </Td>
                  <Td>
                    <ReceiveOrderItemAction
                      variant="small-button"
                      orderItemSubcomponent={ois}
                    />
                    <ReplaceSubcomponentAction
                      variant="small-button"
                      orderItemSubcomponent={ois}
                      orderItem={orderItem}
                    />
                    {orderItem ? (
                      <IconButton
                        variant="ghost"
                        size="sm"
                        aria-label="Open order item context menu"
                        icon={<ContextualMenuIcon />}
                        onClick={() => {
                          openMenu(orderItem);
                        }}
                      />
                    ) : (
                      <ActionButton
                        ml="4"
                        size="xs"
                        colorScheme="red"
                        confirmationMessage="Are you sure you want to delete this component?"
                        action={async () => {
                          await onDeleteSubcomponent(ois);
                        }}
                      >
                        <Text>Delete</Text>
                      </ActionButton>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
