import { ReactElement, useState } from "react";
import { Text, HStack, Button, MenuItem } from "@chakra-ui/react";

export type ActionComponentVariant =
  | "button"
  | "small-button"
  | "primary-action-button"
  | "menu-item";

interface ActionComponentProps {
  variant: ActionComponentVariant;
  actionIcon: ReactElement;
  actionLabel: string;
  action: () => Promise<void>;
}

export const ActionComponent = (props: ActionComponentProps) => {
  const { actionIcon, actionLabel, action, variant, ...rest } = props;
  const [working, setWorking] = useState<boolean>(false);

  const runAction = async () => {
    try {
      setWorking(true);
      await action();
    } finally {
      setWorking(false);
    }
  };

  if (variant === "primary-action-button") {
    return (
      <Button
        isDisabled={working}
        {...rest}
        colorScheme="brand"
        onClick={async () => {
          await runAction();
        }}
      >
        {actionLabel}
      </Button>
    );
  }

  if (variant === "menu-item") {
    return (
      <MenuItem
        isDisabled={working}
        {...rest}
        onClick={async () => {
          await runAction();
        }}
        icon={actionIcon}
      >
        {actionLabel}
      </MenuItem>
    );
  }

  if (variant === "small-button") {
    return (
      <Button
        variant="outline"
        isDisabled={working}
        {...rest}
        size="xs"
        aria-label={actionLabel}
        leftIcon={actionIcon}
        onClick={async () => {
          await runAction();
        }}
      >
        {actionLabel}
      </Button>
    );
  }

  return (
    <Button
      isDisabled={working}
      {...rest}
      onClick={async () => {
        await runAction();
      }}
    >
      <HStack>
        {actionIcon}
        <Text>{actionLabel}</Text>
      </HStack>
    </Button>
  );
};

ActionComponent.defaultProps = {
  variant: "button",
};
