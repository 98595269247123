import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  VisuallyHidden,
  MenuDivider,
} from "@chakra-ui/react";
import { OrderItemDataFragment, Enum_Orderitem_Status } from "data";
import { PrintIcon, ContextualMenuIcon } from "components/Icons";
import { LabelPrinterProvider, useLabelPrinter } from "context/LabelPrinter";
import { CustomerDeviceLabel } from "features/customer-devices/components/CustomerDeviceLabel";
import { DiagnosticsAction } from "features/diagnostics/components/DiagnosticsAction";
import { RepairAction } from "features/repairs/components/RepairAction";
import { QAAction } from "features/qa/components/QAAction";
import { ScrapForPartsAction } from "features/order-items/components/ScrapForPartsAction";
import { ReturnToCustomerAction } from "features/order-items/components/ReturnToCustomerAction";
import { RecycleAction } from "features/order-items/components/RecycleAction";
import {
  CleanAction,
  ReflowAction,
  ApplyThermalPasteAction,
  UpdateFirmwareAction,
  ReplaceControlBoardAction,
  ReplacePSUAction,
  ReplaceFanAction,
} from "features/order-items/components";
import { ShowForOrderItemStatus } from "./ShowForOrderItemStatus";
import { ShowForMiner } from "features/devices/components/ShowForMiner";
import { ShowForHashboard } from "features/devices/components/ShowForHashboard";
import { ShowForPSU } from "features/devices/components/ShowForPSU";

interface OrderItemPageActionsProps {
  orderItem: OrderItemDataFragment;
}

const ConnectedOrderItemPageActions = (props: OrderItemPageActionsProps) => {
  const { orderItem } = props;
  const { printableRef, print } = useLabelPrinter();
  const customerDevice = orderItem.attributes?.customer_device?.data;

  return (
    <>
      {customerDevice ? (
        <VisuallyHidden>
          <CustomerDeviceLabel
            printableRef={printableRef}
            device={customerDevice}
          />
        </VisuallyHidden>
      ) : null}
      <HStack>
        <ShowForOrderItemStatus
          orderItem={orderItem}
          status={[Enum_Orderitem_Status.DiagnosisPending]}
        >
          <DiagnosticsAction
            orderItem={orderItem}
            variant="primary-action-button"
          />
        </ShowForOrderItemStatus>
        <ShowForOrderItemStatus
          orderItem={orderItem}
          status={[
            Enum_Orderitem_Status.DiagnosedNeedsRepair,
            Enum_Orderitem_Status.RepairedFailed,
            Enum_Orderitem_Status.QaFail,
          ]}
        >
          <RepairAction orderItem={orderItem} variant="primary-action-button" />
        </ShowForOrderItemStatus>
        <ShowForOrderItemStatus
          orderItem={orderItem}
          status={[
            Enum_Orderitem_Status.RepairedNeedsQa,
            Enum_Orderitem_Status.DiagnosedIsOk,
          ]}
        >
          <QAAction variant="primary-action-button" orderItem={orderItem} />
        </ShowForOrderItemStatus>
        <ShowForOrderItemStatus
          orderItem={orderItem}
          status={[Enum_Orderitem_Status.QaFailRecycled]}
        >
          <ScrapForPartsAction
            variant="primary-action-button"
            orderItem={orderItem}
          />
        </ShowForOrderItemStatus>
        <Menu>
          <MenuButton
            as={IconButton}
            data-cy="order-item-context-menu"
            aria-label="Options"
            icon={<ContextualMenuIcon />}
            variant="ghost"
          />
          <MenuList>
            <DiagnosticsAction orderItem={orderItem} variant="menu-item" />
            {customerDevice ? (
              <MenuItem
                onClick={() => {
                  print();
                }}
                icon={<PrintIcon />}
              >
                Print Label
              </MenuItem>
            ) : null}
            <ShowForMiner
              device={
                orderItem.attributes?.customer_device?.data?.attributes?.device
                  ?.data
              }
            >
              <>
                <UpdateFirmwareAction orderItem={orderItem} />
                <ReplaceControlBoardAction orderItem={orderItem} />
                <ReplacePSUAction orderItem={orderItem} />
                <ReplaceFanAction orderItem={orderItem} />
              </>
            </ShowForMiner>
            <ShowForHashboard
              device={
                orderItem.attributes?.customer_device?.data?.attributes?.device
                  ?.data
              }
            >
              <>
                <CleanAction orderItem={orderItem} />
                <ReflowAction orderItem={orderItem} />
                <ApplyThermalPasteAction orderItem={orderItem} />
              </>
            </ShowForHashboard>
            <ShowForPSU
              device={
                orderItem.attributes?.customer_device?.data?.attributes?.device
                  ?.data
              }
            >
              <ReplaceFanAction orderItem={orderItem} />
            </ShowForPSU>
            <ShowForOrderItemStatus
              inverse
              orderItem={orderItem}
              status={[
                Enum_Orderitem_Status.QaFailRecycled,
                Enum_Orderitem_Status.QaFailReturnToCustomer,
              ]}
            >
              <>
                <MenuDivider />
                <ReturnToCustomerAction
                  orderItem={orderItem}
                  variant="menu-item"
                />
                <RecycleAction orderItem={orderItem} variant="menu-item" />
                <ScrapForPartsAction
                  orderItem={orderItem}
                  variant="menu-item"
                />
              </>
            </ShowForOrderItemStatus>
          </MenuList>
        </Menu>
      </HStack>
    </>
  );
};

export const OrderItemPageActions = (props: OrderItemPageActionsProps) => {
  return (
    <LabelPrinterProvider>
      <ConnectedOrderItemPageActions {...props} />
    </LabelPrinterProvider>
  );
};
