import {
  OrderItemDataFragment,
  useUpdateOrderItemMutation,
  Enum_Orderitem_Status,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "data";
import { useWrapWithNotifications } from "context/Notifications";
import {
  useCreateAction,
  expandActionDataPiece,
} from "features/actions/data/hooks/actions";

interface UseScrapeForPartsHookData {
  scrapeForParts: () => Promise<OrderItemDataFragment | null>;
}

export const useScrapForParts = ({
  orderItem,
}: {
  orderItem: OrderItemDataFragment;
}): UseScrapeForPartsHookData => {
  const [updateOrderItem] = useUpdateOrderItemMutation();
  const { createAction } = useCreateAction();
  const scrapeForParts = useWrapWithNotifications<void, OrderItemDataFragment>({
    successMessage: "Item scrapped",
    runner: async () => {
      const { data } = await updateOrderItem({
        variables: {
          id: orderItem.id || "",
          data: {
            status: Enum_Orderitem_Status.ExcessiveDamageScrapForParts,
          },
        },
      });

      await createAction({
        type: Enum_Action_Type.ScrapForParts,
        scope: Enum_Action_Scope.OrderItem,
        data: {
          ...expandActionDataPiece(orderItem),
        },
        context: {
          order: orderItem.attributes?.order?.data,
          device: orderItem.attributes?.device?.data,
          customerDevice: orderItem.attributes?.customer_device?.data,
        },
      });

      return data?.updateOrderItem?.data || null;
    },
  });

  return {
    scrapeForParts,
  };
};
