import { OrderItemDataFragment, Enum_Orderitem_Status } from "data";
import { DeleteIcon } from "components/Icons";
import {
  ActionComponent,
  ActionComponentVariant,
} from "components/ActionComponent";
import { ShowForOrderItemStatus } from "features/order-items/components/ShowForOrderItemStatus";
import { useScrapForParts } from "features/order-items/data/hooks/scrap-for-parts";

interface ScrapForPartsActionProps {
  orderItem: OrderItemDataFragment;
  variant: ActionComponentVariant;
}

export const ScrapForPartsAction = (props: ScrapForPartsActionProps) => {
  const { orderItem, variant } = props;
  const { scrapeForParts } = useScrapForParts({ orderItem });
  return (
    <ShowForOrderItemStatus
      orderItem={orderItem}
      status={[Enum_Orderitem_Status.ExcessiveDamageScrapForParts]}
      inverse
    >
      <ActionComponent
        action={async () => {
          await scrapeForParts();
        }}
        variant={variant}
        actionIcon={<DeleteIcon />}
        actionLabel="Scrap for Parts"
        data-cy="action-scrap-for-parts"
      />
    </ShowForOrderItemStatus>
  );
};

ScrapForPartsAction.defaultProps = {
  variant: "button",
};
